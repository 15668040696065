// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-legal-page-js": () => import("./../src/templates/legal-page.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-home-page-js": () => import("./../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-faq-page-js": () => import("./../src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-mint-page-js": () => import("./../src/templates/mint-page.js" /* webpackChunkName: "component---src-templates-mint-page-js" */),
  "component---src-templates-info-page-js": () => import("./../src/templates/info-page.js" /* webpackChunkName: "component---src-templates-info-page-js" */),
  "component---src-templates-press-page-js": () => import("./../src/templates/press-page.js" /* webpackChunkName: "component---src-templates-press-page-js" */),
  "component---src-templates-team-page-js": () => import("./../src/templates/team-page.js" /* webpackChunkName: "component---src-templates-team-page-js" */)
}

